import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import SiteLink from '../../SiteLink'
import ReferenceComponent from '../../references/reference.component'
import heroImgMobile from '../../../assets/images/hero-banner-image-mobile@2x.jpg'
import heroImgDesktop from '../../../assets/images/banners/hero-banner-image-desktop.jpg'
import burdenGraphicBox from '../../../assets/images/hero-banner-text.svg'
import iconRisk from '../../../assets/images/icon-venn-diagram.svg'
import iconChart from '../../../assets/icons/icon-bar-chart.svg'
import { references } from './references'
import { win } from '@src/app-utils'
import Carousel from '@components/carousel/carousel.component'
import ReactPlayer from "react-player/wistia"
import { InView } from 'react-intersection-observer';

import './home.component.scss'
import LeavingSiteModal from '../../leaving-site-modal/leaving-site-modal.component'

export class HomeComponentA extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showRegistrationModal: false,
      href: '',
      showLeavingSiteModal: false,
      leavingModalType: '',
      isMobile: false
    }

    this.handleHideModal = this.handleHideModal.bind(this)
    this.handleShowModal = this.handleShowModal.bind(this)

    this.updateDimensions = this.updateDimensions.bind(this)

  }

  updateDimensions() {
    if (win && window.innerWidth < 1190) {
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }

  handleHideModal() {
    this.setState({
      showRegistrationModal: false,
      showLeavingSiteModal: false,
    })
  }

  handleShowModal() {
    this.setState({
      showRegistrationModal: true,
    })
  }

  handleShowModalClicked() {
    this.setState({
      showLeavingSiteModal: true,
    })
  }


  componentDidMount() {
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
      document.getElementById('background-wrapper').className += ' safari-background'
    }

    const homepageVisited = win && localStorage.getItem('homepageVisited')

    if (!homepageVisited) {
      this.handleShowModal()
    }
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }
  
  openLink(link, type) {
    this.setState(() => {
      return { href: link, leavingModalType: type }
    }, this.handleShowModalClicked())
  }

  render() {
    const { isMobile } = this.state

    return (
      <div className="page-wrapper home-a">
        <div
          id="background-wrapper"
          className="page-background-wrapper"
          aria-label="Tired all the time - Narcolepsy Link campaign"
        >
          <div className="hero-image-wrapper">
            <img
              src={heroImgMobile}
              className="mobile-hero-image"
              alt="Clouds young woman walking rocky terrain carrying boulders on back"
            />
            <img
              src={heroImgDesktop}
              className="desktop-hero-image"
              alt="Clouds young woman walking rocky terrain carrying boulders on back"
            />
          </div>
          <div className="burden-box">
            <img src={burdenGraphicBox} />
          </div>
        </div>

        <div className="cta-tiles-wrapper">
          <div className="cta-tile comorbidities-tile">
            <div className="cta-tile-content">
              <img src={ iconChart } alt="" className="cta-tile-icon"/>
              <div className="cta-tile-text">The prevalence of certain comorbidities is higher in patients with narcolepsy compared with matched controls<sup>3-5</sup></div>
              <SiteLink
                className="cta-tile-button btn-primary"
                to="/comorbidities-risk/cardiovascular-comorbidities/"
                tracking="Home, click, comorbidities - home - a"
                liveRampTracking={true}
              >
                <div className="cta-tile-button-text">
                  See the data&nbsp;&nbsp;
                </div>
                <FontAwesomeIcon className="arrow-right" icon="angle-right" />
              </SiteLink>
            </div>

          </div>
          <div className="cta-tile risk-factors-tile">
            <div className="cta-tile-content">
              <img src={ iconRisk } alt="" className="cta-tile-icon"/>
              <div className="cta-tile-text">Some risk factors for cardiovascular disease in the general population are modifiable with changes to the management plan<sup>6-15</sup></div>
              <SiteLink
                className="cta-tile-button btn-primary"
                to="/comorbidities-risk/risk/"
                tracking="Home, click, modifiable risks - home - a"
                liveRampTracking={true}
              >
                <div className="cta-tile-button-text">
                  Discover the risks&nbsp;&nbsp;
                </div>
                <FontAwesomeIcon className="arrow-right" icon="angle-right" />
              </SiteLink>
            </div>
          </div>
        </div>

        <div className="primary-container">
            { isMobile ? 
            <div className="carousel-mobile">
                { 
                    this.props.carouselData.map((element, i)=>{
                        return(
                        <div key={i} data-key={i} className={'carousel-mobile__content'} >
                            {/* <GatsbyImage image={ this.props.carouselData[i].imgSrc } objectFit="contain" alt={this.props.carouselData[i].thumbAltText}></GatsbyImage> */}
                            <InView threshold={1}>
                            {({ inView, ref, entry }) => (
                            <div ref={ref} key={i} data-key={i}>
                                <ReactPlayer
                                    url={`https://jazzpharma.wistia.com/medias/${this.props.carouselData[i]?.wistiaId}`}
                                    width="100%"
                                    height="250px"
                                    light={false}
                                    controls={false}
                                    playing={inView}
                                    muted={true}
                                    pip={false}
                                    loop={true}
                                    config={{
                                        wistia: {
                                            options: {
                                                silentAutoPlay: true,
                                                endVideoBehavior: 'loop',
                                                playButton: false
                                            }
                                        },
                                    }}
                                    onReady={()=>{document.querySelectorAll('button[aria-label="Play Video"]').forEach((e)=>{e.tabIndex = -1})}}
                                />
                                

                                <p>{this.props.carouselData[i]?.text }</p>
                                <SiteLink
                                    className="cta-tile-button btn-primary carousel-cta"
                                    to={ this.props.carouselData[i]?.buttonLink }
                                    tracking=""
                                    liveRampTracking={true}
                                >
                                    <div className="cta-tile-button-text">
                                        { this.props.carouselData[i]?.buttonText }
                                        <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                                    </div>
                                </SiteLink>
                            </div>
                            )}
                            </InView>
                        </div>)
                    })
                }
            </div>
            : <Carousel data={this.props.carouselData}></Carousel> }
        </div>
        <LeavingSiteModal
          isShowModal={this.state.showLeavingSiteModal}
          onClose={this.handleHideModal}
          href={this.state.href}
          modalType={this.state.leavingModalType}
        />
        <ReferenceComponent className="home-reference" referenceList={references} />
      </div>
    )
  }
}

HomeComponentA.propTypes = {
  history: PropTypes.object,
}

export default HomeComponentA
