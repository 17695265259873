import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import SiteLink from '@components/SiteLink'

import Layout from '@components/layout/Layout'
import HomeComponentA from '@NarcolepsyLink/home/home.component'
import HomeComponentB from '@NarcolepsyLink/home/homeb.component'
import "./index.scss"



class Homepage extends Component {
  constructor(props) {
    super(props)
    this.carouselData = [
        {
            wistiaId: 'v26koaqavp',
            imgSrc: getImage(this.props.data.slide1),
            imgAltText: 'Carousel panel 1, teaser video about sodium effects on the body asset',
            thumbSrc: getImage(this.props.data.slide1Thumb),
            thumbAltText: 'Carousel panel 1 thumbnail, teaser video about sodium effects on the body asset',
            text: ['Certain comorbidities, including ', <SiteLink to={"/comorbidities-risk/cardiovascular-comorbidities/"} className="">cardiovascular (CV) conditions</SiteLink>, ', occur more frequently in patients with narcolepsy compared with matched controls.',<sup>3-5</sup>, ' Some common risk factors for CV disease are ', <SiteLink to={"/comorbidities-risk/risk/"} className="">modifiable</SiteLink>, ', including ',<SiteLink to={"/comorbidities-risk/risk/#HighSodiumIntake"} className="">sodium intake</SiteLink>,'.',<sup>7</sup>],
            buttonText: ['Learn more about the ', <br className='mobile-only'/> ,'impact of high sodium'],
            buttonLink: '/comorbidities-risk/high-sodium-intake/'
        },
        {
            wistiaId: 'ootbtjazy5',
            imgSrc: getImage(this.props.data.slide2),
            imgAltText: 'Carousel panel 2, Expert Interview Clips',
            thumbSrc: getImage(this.props.data.slide2Thumb),
            thumbAltText: 'Carousel panel 2 thumbnail, Expert Interview Clips',
            text: ['Find answers to your questions about narcolepsy, including diagnosis, risk factors for comorbidities, and patient lifestyle modifications.'],
            buttonText: 'Watch expert interviews',
            buttonLink: '/resources/narcolepsy-expert-clinical-insights/'
        },
        {
            wistiaId: 'vs89xwjete',
            imgSrc: getImage(this.props.data.slide3),
            imgAltText: 'Carousel panel 3, Expert Presentations',
            thumbSrc: getImage(this.props.data.slide3Thumb),
            thumbAltText: 'Carousel panel 3 thumbnail, Expert Presentations',
            text: ['Learn more about the complexities, burden, and potential long-term health implications of narcolepsy for your patients.'],
            buttonText: ['Listen to the expert presentations'],
            buttonLink: '/resources/narcolepsy-expert-clinical-insights/#expert-presentations'
        },
        {
            wistiaId: '0pl5t2cqhe',
            imgSrc: getImage(this.props.data.slide4),
            imgAltText: 'Carousel panel 4 (white paper)',
            thumbSrc: getImage(this.props.data.slide4Thumb),
            thumbAltText: 'Carousel panel 4 thumbnail (white paper)',
            text: ['Sleep specialists discuss how recent research has led to a more comprehensive, long-term management approach to narcolepsy.'],
            buttonText: 'Download the white paper',
            buttonLink: '/resources/narcolepsy-expert-clinical-insights/?activeTabIndex=2'
        },
    ]
  }
  render() {
    return (
      <Layout addedClass="page-home" location={this.props.location}>
        <HomeComponentA carouselData={this.carouselData}/>
        <HomeComponentB />
      </Layout>
    )
  }
}

export default Homepage

export const homepageQuery = graphql`
    query homePageQuery {
        slide1: file(relativePath: {eq: "thumbnails/home-carousel-slide-1.png"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 889
                    quality: 90
                )
            }
        },
       slide1Thumb: file(relativePath: {eq: "thumbnails/home-carousel-slide-1.png"}) {
           childImageSharp {
               gatsbyImageData(
                   width: 197
                   quality: 90
               )
           }
       },
       slide2: file(relativePath: {eq: "thumbnails/home-carousel-slide-2.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 889
                quality: 90
            )
        }
       },
       slide2Thumb: file(relativePath: {eq: "thumbnails/home-carousel-slide-2.png"}) {
           childImageSharp {
               gatsbyImageData(
                   width: 197
                   quality: 90
               )
           }
       },
       slide3: file(relativePath: {eq: "thumbnails/home-carousel-slide-3.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 889
                quality: 90
            )
        }
       },
       slide3Thumb: file(relativePath: {eq: "thumbnails/home-carousel-slide-3.png"}) {
           childImageSharp {
               gatsbyImageData(
                   width: 197
                   quality: 90
               )
           }
       },
       slide4: file(relativePath: {eq: "thumbnails/home-carousel-slide-4.png"}) {
        childImageSharp {
            gatsbyImageData(
                width: 889
                quality: 90
            )
        }
       },
       slide4Thumb: file(relativePath: {eq: "thumbnails/home-carousel-slide-4.png"}) {
           childImageSharp {
               gatsbyImageData(
                   width: 197
                   quality: 90
               )
           }
       },
    }
   `