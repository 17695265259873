import React, {useEffect, useState, useRef} from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SiteLink from '@components/SiteLink'
import ReactPlayer from "react-player/wistia"

import './carousel.component.scss'

const Carousel = ({ data }) => {
    const [currentSlideIndex, setCurrentSlideIndex ] = useState(0);
    const thumbnailSelectorRef = useRef(null)

    const selectSlide = (e) => {
        if(e.keyCode === 13 || e.type === 'click') {
            setCurrentSlideIndex(e.currentTarget.dataset.key)
        }
    }

    //update to be inline
    const removeActiveThumbnailStyle = () => {
        thumbnailSelectorRef.current.childNodes.forEach(thumbnail => {
            thumbnail.classList.remove('carousel__thumbnail--selected')
        });
    }

    const setActiveThumbnailStyle = () => {
        thumbnailSelectorRef.current.querySelector('[data-key="' + currentSlideIndex + '"]').classList.add('carousel__thumbnail--selected')
    }

	useEffect(()=>{
        removeActiveThumbnailStyle();
        setActiveThumbnailStyle();
	},[currentSlideIndex])


    return (
        <div className="carousel-wrapper">
            <div className="carousel__thumbnail-selector" ref={thumbnailSelectorRef} >
                { 
                    data.map((element, i)=>{
                        return(
                        <div key={i} data-key={i} tabIndex={0} className={'carousel__thumbnail'} onClick={(e)=>{ selectSlide(e) }} onKeyDown={(e)=>{ selectSlide(e) }}>
                            <GatsbyImage  key={'imageKey' + i} image={ data[i].thumbSrc } objectFit="contain" alt={data[i].thumbAltText}></GatsbyImage>
                            <div className="carousel__thumbnail-outline"></div>
                        </div>)
                    })
                }
            </div>
            <div className="carousel__content">
                    <ReactPlayer
                        url={`https://jazzpharma.wistia.com/medias/${data[currentSlideIndex]?.wistiaId}`}
                        width="889px"
                        height="100%"
                        light={false}
                        controls={false}
                        playing={true}
                        muted={true}
                        pip={false}
                        loop={true}
                        config={{
                            wistia: {
                                options: {
                                    silentAutoPlay: true,
                                    endVideoBehavior: 'loop'
                                }
                            },
                        }}
                        onReady={()=>{document.querySelectorAll('button[aria-label="Play Video"]').forEach((e)=>{e.tabIndex = -1})}}
                    />
                <div className="carousel__info">
                    <p>{data[currentSlideIndex]?.text }</p>
                    <SiteLink
                        className="cta-tile-button btn-primary carousel-cta"
                        to={ data[currentSlideIndex]?.buttonLink }
                        tracking=""
                        liveRampTracking={true}
                    >
                        <div className="cta-tile-button-text">
                            { data[currentSlideIndex]?.buttonText }
                        </div>
                        <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                    </SiteLink>
                </div>
            </div>
        </div>
	);
};

Carousel.defaultProps = {
	children: null,
};

Carousel.propTypes = {

};

export default Carousel;
